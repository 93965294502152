import React from 'react'
import GoogleMapReact from 'google-map-react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import './map.css'

const MapMarker = ({ lat, lng }) => (
  <div class="marker-container">
    <div class="marker">
      <FaMapMarkerAlt
        size='32'
        color='green'
        lat={lat}
        lng={lng}
        className='marker-icon'
      />
      <p class="marker-text">Resilience PT & Wellness</p>
    </div>
    <a class="marker-link" href="https://www.google.com/maps/dir//110+E+3rd+St+%23212,+Rifle,+CO+81650/@39.5320926,-107.7839507,18.35z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8741392116020061:0x63c7a1ca8c2697eb!2m2!1d-107.7828681!2d39.5318733!3e0?entry=ttu">
      Directions
    </a>
  </div>
)

const GoogleMap = ({ content, lat, lng, zoom }) => (
  <div className="google-map">
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GATSBY_GOOGLE_MAP_KEY
      }}
      defaultCenter={{
        lat: content.lat || lat,
        lng: content.lng || lng
      }}
      defaultZoom={content.zoom || zoom}
    >
      <MapMarker lat={content.lat || lat} lng={content.lng || lng} />
    </GoogleMapReact>
  </div>
)

GoogleMap.defaultProps = {
  lat: 59.95,
  lng: 30.33,
  zoom: 12
}

export default GoogleMap
